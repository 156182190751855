import * as React from "react";
import kebabCase from "lodash.kebabcase";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import { Content, HTMLContent } from "../components/Content";

export class BlogPostTemplate extends React.Component<{
  content;
  contentComponent?;
  description;
  tags;
  title;
  helmet?;
}> {
  render() {
    const PostContent = this.props.contentComponent || Content;

    return (
      <Layout>
        <Helmet title={this.props.title}>
          <meta name="description" content={this.props.description} />
        </Helmet>
        <section>
          {this.props.helmet || ""}
          <div>
            <div>
              <div>
                <h1>{this.props.title}</h1>
                <p>{this.props.description}</p>
                <PostContent content={this.props.content} />
                {this.props.tags && this.props.tags.length ? (
                  <div>
                    <h4>Tags</h4>
                    <ul>
                      {this.props.tags.map(tag => (
                        <li key={tag + `tag`}>
                          <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

class BlogPost extends React.Component<{ data }> {
  render() {
    const { markdownRemark: post } = this.props.data;

    return (
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={<Helmet title={`${post.frontmatter.title} | Blog`} />}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    );
  }
}

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`;
